/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import 'animate.css/animate.min';
@import '~tippy.js/dist/tippy.css';
@import '~tippy.js/animations/scale.css';

@font-face {
  font-family: 'Source Sans 3';
  font-style: italic;
  font-weight: 200;
  src: url(./assets/fonts/SourceSans3/SourceSans3-ExtraLightItalic.woff2) format('woff2');
}

@font-face {
  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 200;
  src: url(./assets/fonts/SourceSans3/SourceSans3-ExtraLight.woff2) format('woff2');
}

@font-face {
  font-family: "Source Sans 3";
  font-style: italic;
  font-weight: 300;
  src: url(./assets/fonts/SourceSans3/SourceSans3-LightItalic.woff2) format('woff2');
}

@font-face {
  font-family: "Source Sans 3";
  font-style: normal;
  font-weight: 300;
  src: url(./assets/fonts/SourceSans3/SourceSans3-Light.woff2) format('woff2');
}

@font-face {
  font-family: "Source Sans 3";
  font-style: italic;
  font-weight: 400;
  src: url(./assets/fonts/SourceSans3/SourceSans3-Italic.woff2) format('woff2');
}

@font-face {
  font-family: "Source Sans 3";
  font-style: normal;
  font-weight: 400;
  src: url(./assets/fonts/SourceSans3/SourceSans3-Regular.woff2) format('woff2');
}

@font-face {
  font-family: "Source Sans 3";
  font-style: italic;
  font-weight: 500;
  src: url(./assets/fonts/SourceSans3/SourceSans3-MediumItalic.woff2) format('woff2');
}

@font-face {
  font-family: "Source Sans 3";
  font-style: normal;
  font-weight: 500;
  src: url(./assets/fonts/SourceSans3/SourceSans3-Medium.woff2) format('woff2');
}

@font-face {
  font-family: "Source Sans 3";
  font-style: italic;
  font-weight: 600;
  src: url(./assets/fonts/SourceSans3/SourceSans3-SemiBoldItalic.woff2) format('woff2');
}

@font-face {
  font-family: "Source Sans 3";
  font-style: normal;
  font-weight: 600;
  src: url(./assets/fonts/SourceSans3/SourceSans3-SemiBold.woff2) format('woff2');
}

@font-face {
  font-family: "Source Sans 3";
  font-style: italic;
  font-weight: 700;
  src: url(./assets/fonts/SourceSans3/SourceSans3-BoldItalic.woff2) format('woff2');
}

@font-face {
  font-family: "Source Sans 3";
  font-style: normal;
  font-weight: 700;
  src: url(./assets/fonts/SourceSans3/SourceSans3-Bold.woff2) format('woff2');
}

@font-face {
  font-family: "Source Sans 3";
  font-style: italic;
  font-weight: 800;
  src: url(./assets/fonts/SourceSans3/SourceSans3-ExtraBoldItalic.woff2) format('woff2');
}

@font-face {
  font-family: "Source Sans 3";
  font-style: normal;
  font-weight: 800;
  src: url(./assets/fonts/SourceSans3/SourceSans3-ExtraBold.woff2) format('woff2');
}

@font-face {
  font-family: "Source Sans 3";
  font-style: italic;
  font-weight: 900;
  src: url(./assets/fonts/SourceSans3/SourceSans3-BlackItalic.woff2) format('woff2');
}

@font-face {
  font-family: "Source Sans 3";
  font-style: normal;
  font-weight: 900;
  src: url(./assets/fonts/SourceSans3/SourceSans3-Black.woff2) format('woff2');
}

:root {
	--ion-color-start-button: #003399;
	--ion-color-start-button-rgb: 0,51,153;
	--ion-color-start-button-contrast: #ffffff;
	--ion-color-start-button-contrast-rgb: 255,255,255;
	--ion-color-start-button-shade: #002d87;
	--ion-color-start-button-tint: #1a47a3;

	--ion-color-avatar-choose: #ffffff;
	--ion-color-avatar-choose-rgb: 0,51,153;
	--ion-color-avatar-choose-contrast: #003399;
	--ion-color-avatar-choose-contrast-rgb: 255,255,255;
	--ion-color-avatar-choose-shade: #002d87;
	--ion-color-avatar-choose-tint: #1a47a3;
	--height-green-graph: 4px;
	--y-translate-green-graph: 4px;
	--height-white-graph: 4px;
	--y-translate-white-graph: 4px;
	--color-primary-blue: #003399;
	--color-secondary-blue: #3266CC;
	--color-light-blue: #CFE7ED;
	--color-green: #497845;
	--color-white: #FFFFFF;
  --color-yellow-box: #D1AF1F;
  --color-yellow-bookmark: #F5DB6C;
  --color-primary-gray: #585958;
  --color-negotiable-green: #005840;
  --color-negotiable-light-green: #C0D19B;
  --color-sensitive-brown: #5B4C40;
  --color-red_line-red: #B82924;
}

.ion-color-start-button {
	--ion-color-base: var(--ion-color-start-button);
	--ion-color-base-rgb: var(--ion-color-start-button-rgb);
	--ion-color-contrast: var(--ion-color-start-button-contrast);
	--ion-color-contrast-rgb: var(--ion-color-start-button-contrast-rgb);
	--ion-color-shade: var(--ion-color-start-button-shade);
	--ion-color-tint: var(--ion-color-start-button-tint);
}

.ion-color-avatar-choose {
	--ion-color-base: var(--ion-color-avatar-choose);
	--ion-color-base-rgb: var(--ion-color-avatar-choose-rgb);
	--ion-color-contrast: var(--ion-color-avatar-choose-contrast);
	--ion-color-contrast-rgb: var(--ion-color-avatar-choose-contrast-rgb);
	--ion-color-shade: var(--ion-color-avatar-choose-shade);
	--ion-color-tint: var(--ion-color-avatar-choose-tint);
}

ion-item::part(native) {
  --ion-safe-area-left: 0px;
  --ion-safe-area-right: 0px;
}

ion-button { text-transform: none !important; }

.select-popover, .alertControl {
	pointer-events: all;
}

.w-h-100 {
  width: 100%;
  height: 100vh;
}

.h-100 {
  height: 100%;
}

.w-100 {
  width: 100%;
}

.w-25 {
  width: 25%;
}

.w-50 {
  width: 50%;
}

html {
	font-size: 24px;
  font-family: 'Source Sans 3', "Roboto", sans-serif;
}

.notch-hide {
  border-left: env(safe-area-inset-left) solid black;
  box-sizing: border-box;
}

html.ios {
  --ion-default-font: "Source Sans 3", -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Roboto", sans-serif;
}
html.md {
  --ion-default-font: "Source Sans 3", "Roboto", "Helvetica Neue", sans-serif;
}
.pointer-events-none {
  pointer-events: none;
}

.pointer-events-all {
  pointer-events: all;
}

.not-selectable {
  user-drag: none;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.line-clamp {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 1;

  &.two {
    -webkit-line-clamp: 2;
  }

  &.three {
    -webkit-line-clamp: 3;
  }

  &.four {
    -webkit-line-clamp: 4;
  }
}

.points{
  width: 1.3rem;
}
.pointsSpotCountry {
  width: 0.8rem;
}

.country-w-flag {
  display: inline-flex;
  align-items: center;

  .country {
    font-size: 1rem;
    font-weight: bold;
  }
  .flag {
    width: 1rem;
    height: 1rem;
    margin-right: 0.417rem;
    box-shadow: 0 2px 0 0 rgba(0,0,0,0.16);
    border-radius: 50%;
  }
}

.text-w-padding {
  margin: 0 0.264rem;
}

.text-bold {
  font-weight: bold;
}

.country-flag {
  width: 1rem;
  height: 1rem;
  margin-bottom: -0.25rem;
  border-radius: 50%;
  box-shadow: 0 2px 0 0 rgb(0 0 0 / 16%);
}

.tippy-box {
  background-color: var(--color-white);
  color: var(--color-primary-blue);
  padding: 0.4rem;
  border-radius: 0.458rem;
  margin-bottom: 0.4rem;
  max-width: 20.833rem !important;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  margin-left: 4rem;
  font-size: 1rem;
}

.tippy-arrow {
  color: var(--color-white);
  left: -0.417rem !important
}

:root {
  ion-select.select-country::shadow .select-text.select-placeholder+.select-text {
    display: none;
  }

  ion-select.selectWrap::part(text) {
    white-space: normal !important;
  }

  ion-popover {
    --width: 33.33%;
    --max-height: 20rem;

    &.popover-wide {
      --min-width: 250px;
    }

    &.countries ion-radio {
      .countryName {
        margin-left: 2rem;
      }
    }

    &.lang-wide {
      --width: auto;
    }
  }

  .item.sc-ion-label-md-h, .item .sc-ion-label-md-h,
  .item.sc-ion-label-ios-h, .item .sc-ion-label-ios-h {
    white-space: normal !important;
  }
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  white-space: nowrap;
}

::-webkit-scrollbar {
  width: 0.125rem;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: var(--color-primary-blue);
  border-radius: 7px;
}

* {
  scrollbar-color: var(--color-primary-blue) transparent; /*firefox*/
  scrollbar-width: thin;
}

